import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DeviceRoutingModule } from './device-routing.module';
import { DeviceComponent } from './device.component';
import { ParameterSensorDeviceComponent } from './components/parameter-sensor-device/parameter-sensor-device.component';
import { ParameterSensorEventLoggerComponent } from './components/parameter-sensor-event-logger/parameter-sensor-event-logger.component';


@NgModule({
  declarations: [DeviceComponent, ParameterSensorDeviceComponent, ParameterSensorEventLoggerComponent],
  imports: [
    CommonModule,
    DeviceRoutingModule
  ]
})
export class DeviceModule { }
