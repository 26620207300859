import {Component, OnDestroy, OnInit} from '@angular/core';
import {PayloadData} from "../../../../lorawan/models/payload-defined/payload-data";
import {EventState} from "../../../../lorawan/states/event/event.state";
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'app-parameter-sensor-device',
  templateUrl: './parameter-sensor-device.component.html',
  styleUrls: ['./parameter-sensor-device.component.scss']
})
export class ParameterSensorDeviceComponent implements OnInit{

  eventDevice: any;
  listValueSensor = new BehaviorSubject([]);
  isListValue = this.listValueSensor.asObservable();
  deviceId: string;
  constructor(private eventState: EventState,
              private router: ActivatedRoute) { }

  ngOnInit(): void {
    this.deviceId = this.router.snapshot.params['deviceId'];
    this.listenState();
  }


  listenState(): void{
    this.eventState.loadEventByDeviceId(this.deviceId);
    this.eventState.isEvent.subscribe(res => {
      this.eventDeviceChange();
    });
  }

  eventDeviceChange(): void{
    const event = this.eventState.getEvent();
    if (event) {
      this.eventDevice = event;
      console.log(event);
      this.formatPayloadValueSensor();
    }
  }
  formatPayloadValueSensor(): void{
    let array = [];
    const payload = this.eventDevice.payload;
    for (let item in payload){
      Object.values(PayloadData).forEach(i => {
        if(i.name === item){
          array.push({
            title: i.title,
            icon: i.icon,
            unit: i.unit,
            value: payload[item]
          })
        }
      })
    }
    this.listValueSensor.next(array);
    console.log(array);
  }
}
