import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IntroRoutingModule } from './intro-routing.module';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { LandingComponent } from './landing/landing.component';
import { ContactComponent } from './contact/contact.component';


@NgModule({
    declarations: [NavbarComponent, FooterComponent, LandingComponent, ContactComponent],
    exports: [
        NavbarComponent
    ],
    imports: [
        CommonModule,
        IntroRoutingModule
    ]
})
export class IntroModule { }
