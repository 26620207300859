import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TranslationService } from './lorawan/i18n/translation.service';
// language list
import { locale as enLang } from './lorawan/i18n/vocabs/en';
import { locale as vnLang } from './lorawan/i18n/vocabs/vi';
import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service';
import { Router, NavigationEnd, NavigationError } from '@angular/router';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import { TableExtendedService } from './_metronic/shared/crud-table';
import {GardenSocketService} from "./lorawan/services/socket/garden-socket.service";
import {NotifierService} from "./lorawan/services/notify/notifier.service";
import {StorageService} from "./lorawan/services/storage/storage.service";
import {AuthService} from "./lorawan/services/auth/auth.service";
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  token$: Observable<any> = this.tokenSubject.asObservable();
  authData: any;
  constructor(
    private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    private router: Router,
    private tableService: TableExtendedService,
    private gardenSocket: GardenSocketService,
    private notifiesService: NotifierService,
    private storageService: StorageService,
    private authService: AuthService,
  ) {
    // register translations
    // this.translationService.loadTranslations(
    //   enLang,
    //   vnLang
    // );
  }

  ngOnInit() {
    this.refreshToken();
    setInterval(() => this.refreshToken(), 300000);
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // clear filtration paginations and others
        this.tableService.setDefaults();
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, 800);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
  refreshToken(){
    this.authData = this.authService.getAuthFromLocalStorage();
    if (this.authData.refreshToken !== null && this.authData.accessToken !== null){
      this.authService.refreshToken(this.authData.refreshToken).subscribe(
          res => {
            if (res === undefined){
              this.authService.logout();
              this.notifiesService.error('Hết hạn đăng nhập');
            }else {
              this.tokenSubject.next(res);
              this.storageService.saveToken(this.tokenSubject.getValue());
            }
          }, error => {
            this.authService.logout();
            this.notifiesService.error('Hết hạn đăng nhập');
            console.log(error);
          }
      );
    }
  }
}
