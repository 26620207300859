import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import * as _ from 'lodash';
import {StorageService} from '../storage/storage.service';
import {environment} from '../../../../environments/environment';
import {ApiPath} from '../../constance/api-path';


const url = environment.lorawanUrl;
const gardenPath = ApiPath.account;
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient,
              private storageService: StorageService
  ) {
  }


  public setHeaders(headers?: any): HttpHeaders {
    const token = 'Bearer ' + this.storageService.getToken();
    let httpHeaders;

    if (token) {
      try {
        httpHeaders = new HttpHeaders(_.assign({
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: token
        }, headers));
      } catch (error) {
        // todo
        // this.storageService.deleteAll();
      }
    }

    return httpHeaders;
  }


  public setUrlEncodedHeaders(headers?: any): HttpHeaders {
    const token = 'Bearer ' + this.storageService.getToken();
    let httpHeaders;

    if (token) {
      try {
        httpHeaders = new HttpHeaders(_.assign({
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: token
        }, headers));
      } catch (error) {
        // todo
        // this.storageService.deleteAll();
      }
    }

    return httpHeaders;
  }


  private errorHandler(error: HttpErrorResponse) {
    if (error.status === 401){
      // const path = `${url}/${gardenPath.refreshToken}`;
      // this.httpClient.post(path, {token: this.storageService.getRefreshToken()}).pipe(
      //     map((data: any) => {
      //       this.storageService.saveToken(data.body.accessToken);
      //     }), catchError(err => {
      //       console.log(err);
      //       return of(undefined);
      //     })
      // );
    }
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred: ', error.error.message);
    } else {
      return throwError(error);
    }
    return throwError('Something went wrong!');
  }


  public post(path: string, body: any, customHeader?: any): Observable<HttpResponse<any>> {
    return this.httpClient.post<any>(
      path, body,
      {
        headers: this.setHeaders(customHeader),
        withCredentials: false,
        observe: 'response'
      })
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public postUrlEncoded(path: string, body: any, customHeader?: any): Observable<HttpResponse<any>> {
    return this.httpClient.post<any>(
      path, body,
      {
        headers: this.setUrlEncodedHeaders(customHeader),
        withCredentials: false,
        observe: 'response'
      })
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public get(path: string, options?, params?: HttpParams): Observable<any> {
    return this.httpClient.get(
      path,
      {
        headers: this.setHeaders(options),
        params,
        withCredentials: false,
        observe: 'response'
      })
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public put(path: string, body?: any): Observable<any> {
    return this.httpClient.put(
      path, body,
      {
        headers: this.setHeaders(),
        withCredentials: false,
        observe: 'response'
      })
      .pipe(
        catchError(this.errorHandler)
      );
  }


  public delete(path: string): Observable<any> {
    return this.httpClient.delete(
      path,
      {
        headers: this.setHeaders(),
        withCredentials: false,
        observe: 'response'
      })
      .pipe(
        catchError(this.errorHandler)
      );
  }
}
