export const PayloadData = {
  batV: {
    name: 'batV',
    title: 'Dung lượng pin',
    description: 'Dung lượng pin hiện tại Node',
    unit: 'V',
    i18n: null,
    uiIndex: 0,
    icon: "fas fa-bolt text-warning",
    type: 'batV'
  },
  hum_SHT: {
    name: 'hum_SHT',
    title: 'Độ ẩm không khí',
    description: 'Độ ẩm không khí',
    unit: '%',
    i18n: null,
    uiIndex: 1,
    icon: "fas fa-tint text-primary",
    type: "air_humi"
  },
  tempC_SHT: {
    name: 'tempC_SHT',
    title: 'Nhiệt độ không khí',
    description: 'Nhiệt độ không khí',
    unit: '°C',
    i18n: null,
    uiIndex: 3,
    icon: "fas fa-temperature-high text-danger",
    type: 'air_temp'
  },

  illum: {
    name: 'illum',
    title: 'Cường độ ánh sáng',
    description: 'Cường độ ánh sáng',
    unit: 'Lux',
    i18n: null,
    uiIndex: 4,
    icon: "fas fa-sun text-warning",
    type: 'illumi'
  },

  conduct_SOIL: {
    name: 'conduct_SOIL',
    title: 'Độ dẫn điện trong đất (EC)',
    description: 'Độ dẫn điện trong đất (EC)',
    unit: 'uS/cm',
    i18n: null,
    uiIndex: 5,
    icon: "fas fa-bolt text-warning",
    type: 'soil_ec'
  },
  temp_SOIL: {
    name: 'temp_SOIL',
    title: 'Nhiệt độ trong đất',
    description: 'Nhiệt độ trong đất',
    unit: '°C',
    i18n: null,
    uiIndex: 6,
    icon: "fas fa-temperature-high text-dark",
    type: 'soil_temp'
  },
  water_SOIL: {
    name: 'water_SOIL',
    title: 'Độ ẩm đất',
    description: 'Độ ẩm đất',
    unit: '%',
    i18n: null,
    uiIndex: 7,
    icon: "fas fa-seedling text-success",
    type: 'soil_humi'
  }
}
