import {Injectable} from '@angular/core';
import {StorageInfo} from '../../constance/storage-info';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';


@Injectable({
    providedIn: 'root'
})
export class StorageService {
    constructor(
        public local: LocalStorageService,
        public session: SessionStorageService
    ) {
    }

    // region System effect
    getToken(): string {
        return this.local.retrieve(StorageInfo.TOKEN);
    }

    saveToken(token) {
        this.local.store(StorageInfo.TOKEN, token);
    }

    getIdToken(): string {
        return this.local.retrieve(StorageInfo.ID_TOKEN);
    }

    saveIdToken(idToken: string) {
        this.local.store(StorageInfo.ID_TOKEN, idToken);
    }

    getRefreshToken(): string {
        return this.local.retrieve(StorageInfo.REFRESH_TOKEN);
    }

    saveRefreshToken(refreshToken: string) {
        this.local.store(StorageInfo.REFRESH_TOKEN, refreshToken);
    }

    getExpiryTime(): number {
        return this.local.retrieve(StorageInfo.EXPIRY_TIME);
    }

    saveExpiryTime(expTime: number) {
        this.local.store(StorageInfo.EXPIRY_TIME, expTime);
    }

    getCollector() {
        return this.local.retrieve(StorageInfo.COLLECTOR);
    }

    saveCollector(collector) {
        try {
            this.local.store(StorageInfo.COLLECTOR, collector);
        } catch (e) {
            this.deleteAll();
        }
        return '';
    }

    deleteAll() {
        this.local.clear();
        this.session.clear();
    }
    // endregion
}

