import {Injectable, OnDestroy} from "@angular/core";
import {BehaviorSubject, Subscription} from "rxjs";
import {EventService} from "../../services/event/event.service";
import {finalize, tap} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})

export  class EventState implements  OnDestroy{

    private eventSub = new BehaviorSubject<any>(null);
    public isEvent = this.eventSub.asObservable();
    private subscription = new Subscription();
    private eventLogger = new BehaviorSubject<any>(null);
    public eventLogger$ = this.eventLogger.asObservable();
    constructor(
        private eventService: EventService
    ) {

    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
    getEvent(): any {
        return this.eventSub.getValue();
    }
    setEvent(event: any) {
        this.eventSub.next(event);
    }

    getEventLogger(): any{
        return this.eventLogger.getValue();
    }
    setEventLogger(eventLogger: any) {
        this.eventLogger.next(eventLogger);
    }

    loadEventByDeviceId(deviceId): void{
        const sb = this.eventService.getEventByDeviceId(deviceId)
            .pipe(
                tap(event => {
                    this.setEvent(event);
                })
            )
            .subscribe();
        this.subscription.add(sb);
    }

    loadEventLoggerByLoggerId(loggerId): void{
        const sb = this.eventService.getEventLoggerByLoggerID(loggerId)
            .pipe(
                tap(event => {
                    this.setEventLogger(event);
                })
            )
            .subscribe();
        this.subscription.add(sb);
    }
}


