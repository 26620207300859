import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  listService = [
    {
      title: 'Lắp đặt cảm biến máy bơm',
      image: '../../../assets/media/intro/wrench.png',
      message: 'Chúng tôi cúng cấp dịch vụ lắp đặt cảm biến và máy bơm cho khu vườn của bạn một cách nhanh chóng ngay khi bạn liên lạc với chúng tôi'
    },
    {
      title: 'Theo dõi các chỉ số cảm biến',
      image: '../../../assets/media/intro/remote-control.png',
      message: 'Hệ thống sẽ hỗ trợ bạn theo dõi các chỉ số của các thông số cảm biến của khu vườn bạn một cách nhanh chóng.'
    },
    {
      title: 'Trực quan hóa biểu đồ dữ liệu',
      image: '../../../assets/media/intro/infographic.png',
      message: 'Hệ thống sẽ trực quan hóa dữ liệu gửi từ cảm biến về bằng biểu đồ. Việc này giúp bạn dễ dàng theo dõi sự thay đổi của khu vườn trong tháng vừa qua.'
    },
    {
      title: 'Bật tắt máy bơm từ xa',
      image: '../../../assets/media/intro/remote-control-monitor.png',
      message: 'Hệ thống sẽ hỗ trợ bạn bật tắt máy bơm từ xa. Bạn có thể bật tắt và theo dõi tình trạng máy bơm trong khu vườn của bạn ở bất kỳ đâu.'
    },
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
