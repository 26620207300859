<footer class="footer-distributed">

    <div class="footer-left">

        <h3><img class="image" src="../../../assets/media/logos/logo-letter-1.png" alt="logo"> smartAgri</h3>

        <p class="footer-links">
            <a routerLink="/intro/contact" class="link-1">Liên Hệ</a>

            <a routerLink="/auth/login">Đăng nhập</a>
        </p>

        <!--        <p class="footer-company-name">Company Name © 2015</p>-->
    </div>

    <div class="footer-center">

        <div>
            <i class="bi bi-geo-alt-fill"></i>
            <p><span>146 Phan Huy Ích</span>TP. Hồ Chí Minh, Việt Nam</p>
        </div>

        <div>
            <i class="bi bi-telephone-fill"></i>
            <p>0985140102</p>
        </div>

        <div>
            <i class="bi bi-envelope-fill"></i>
            <p><a href="mailto:support@company.com">lam@gmail.com</a></p>
        </div>

    </div>

    <div class="footer-right">

        <p class="footer-company-about">
            <span>Về chúng tôi</span>
            Chúng tôi là một nhóm các thành viên kỹ sư lập trình. Làm vì đam mê :)).
        </p>

        <div class="footer-icons">

            <a href="#"><i class="bi bi-facebook"></i></a>
            <a href="#"><i class="bi bi-twitter"></i></a>
            <a href="#"><i class="bi bi-github"></i></a>

        </div>

    </div>

</footer>
