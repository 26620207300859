import { Component, OnInit } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {EventState} from "../../../../lorawan/states/event/event.state";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-parameter-sensor-event-logger',
  templateUrl: './parameter-sensor-event-logger.component.html',
  styleUrls: ['./parameter-sensor-event-logger.component.scss']
})
export class ParameterSensorEventLoggerComponent implements OnInit {

  eventDevice: any;
  listValueSensor = new BehaviorSubject([]);
  isListValue = this.listValueSensor.asObservable();
  deviceId: string;
  constructor(private eventState: EventState,
              private router: ActivatedRoute) { }

  ngOnInit(): void {
    this.deviceId = this.router.snapshot.params['deviceId'];
    this.listenState();
  }


  listenState(): void{
    this.eventState.loadEventLoggerByLoggerId(this.deviceId);
    this.eventState.eventLogger$.subscribe(res => {
      this.eventDeviceChange();
    });
  }

  eventDeviceChange(): void{
    const event = this.eventState.getEventLogger();
    if (event) {
      this.eventDevice = event;
      console.log(event);
      this.formatPayloadValueSensor();
    }
  }
  formatPayloadValueSensor(): void{
    let array = [];
    const payload = this.eventDevice[0].data.data;
    for(let item in payload){
      if (item === 'Light'){
        array.push({
          title: 'Cường độ ánh sáng',
          icon: 'fas fa-sun text-warning',
          unit: 'Lux',
          value: payload[item]
        })
      }else if(item === 'Humi'){
        array.push({
          title: 'Độ ẩm không khí',
          icon: 'fas fa-tint text-primary',
          unit: '%',
          value: payload[item]
        })
      }else if (item === 'Temp'){
        array.push({
          title: 'Nhiệt độ không khí',
          icon: 'fas fa-temperature-high text-danger',
          unit: '°C',
          value: payload[item]
        })
      }else {
        array.push({
          title: item,
          icon: null,
          unit: null,
          value: payload[item]
        })
      }
    }
    this.listValueSensor.next(array);
    console.log(array);
  }

}
