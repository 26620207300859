import {count} from "rxjs/operators";

export const ApiPath = {
  user: {
    user: 'user',
    me: 'user/me',
    update: 'user/update'
  },
  account: {
    login: 'auth/login',
    register: 'auth/register',
    refreshToken: 'auth/token',
    users: 'users',
    changePassword: 'auth/change-password'
  },
  garden: {
    gardens: 'gardens'
  },
  gardenSensors : {
    gardenSensors: 'garden-sensors',
    limit: (id) => `garden-sensors/${id}/limit`
  },
  chart: {
    charts: 'charts'
  },
  monitor: {
    historiesGarden: (gardenId) => `monitor/histories/${gardenId}`,
    seeMoreHistories: (gardenId, count) => `monitor/histories/${gardenId}/${count}`,
    postRelay: (relayId) => `monitor/${relayId}`,
    setting: (relayId) => `setting/${relayId}`
  },
  notification: {
    notification: (type) => `notifications/${type}`,
    seeMoreNotification: (countNotify, type) => `notifications/${type}/${countNotify}`,
    updateNotification: (notificationId) => `notifications/${notificationId}`
  },
  event: {
    getEventByDeviceId: (deviceId) => `events/device/${deviceId}` ,
    getEventLoggerByLoggerId: (loggerId) => `event/logger?loggerId=${loggerId}`
  }
};
