import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DeviceComponent} from "./device.component";
import {ParameterSensorDeviceComponent} from "./components/parameter-sensor-device/parameter-sensor-device.component";
import {ParameterSensorEventLoggerComponent} from "./components/parameter-sensor-event-logger/parameter-sensor-event-logger.component";

const routes: Routes = [
  {
    path: '',
    component: DeviceComponent,
    children: [
      {
        path: 'lorawan/:deviceId',
        component: ParameterSensorDeviceComponent
      },
      {
        path: 'traditional/:deviceId',
        component: ParameterSensorEventLoggerComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeviceRoutingModule { }
