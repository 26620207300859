import { Injectable } from '@angular/core';
import {ApiService} from '../_core/api.service';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {catchError, finalize, map, switchMap} from 'rxjs/operators';
import {StorageService} from '../storage/storage.service';
import {ApiPath} from '../../constance/api-path';
import {LorawanUser} from '../../models/user/lorawan-user';
import {Router} from '@angular/router';


const GardenAuthPath = ApiPath.account;
const UserPath = ApiPath.user;
@Injectable({
  providedIn: 'root'
})

export class AuthService {
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  currentUserSubject: BehaviorSubject<LorawanUser>;

  constructor(
      private apiService: ApiService,
      private storageService: StorageService,
      private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }
  get currentUserValue(): boolean{
    return !!this.storageService.getRefreshToken();
  }
  changePassword(data: any): Observable<any>{
    const path = `${environment.lorawanUrl}/${GardenAuthPath.changePassword}`;
    return this.apiService.put(path, data);
  }
  login(data: any): Observable<any>{
    const path = `${environment.lorawanUrl}/${GardenAuthPath.login}`;
    return this.apiService.post(path, data).pipe(
        map((auth: any) => {
          const result = this.setAuthFormLocalStorage(auth);
          return result;
        }),
        catchError((err) => {
          console.error('err', err);
          return of(undefined);
        }),
        finalize(() => this.isLoadingSubject.next(false))
    );
  }
  logout(){
    this.storageService.deleteAll();
    this.router.navigate(['/']);
  }
  register(data: any): Observable<any>{
    const path = `${environment.lorawanUrl}/${GardenAuthPath.register}`;
    return this.apiService.post(path, data).pipe(
        map(() => {
          this.isLoadingSubject.next(false);
        }),
        switchMap( () => this.login({email: data.email, password: data.password})),
    );
    finalize(() => this.isLoadingSubject.next(false));
  }
  refreshToken(refreshToken: string): Observable<any>{
    const path = `${environment.lorawanUrl}/${GardenAuthPath.refreshToken}`;
    return this.apiService.post(path, {token: refreshToken}).pipe(
        map((data: any) => {
          return data.body.accessToken;
        }),
        catchError((err) => {
          console.log('err', err);
          return of(undefined);
        }),
    );
    finalize(() => this.isLoadingSubject.next(false));
  }
  private setAuthFormLocalStorage(auth: any): boolean{
    if (auth){
      this.storageService.saveToken(auth.body.accessToken);
      this.storageService.saveRefreshToken(auth.body.refreshToken);
      return true;
    }
    return false;
  }
  public getAuthFromLocalStorage(): any{
    try{
      const authData = {
        accessToken: this.storageService.getToken(),
        refreshToken: this.storageService.getRefreshToken()
      };
      return authData;
    }catch (error) {
      console.error(error);
      return undefined;
    }
  }
}
