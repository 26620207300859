<div class="header ">
    <div class="">
        <img
            class="logo-iuh"
            src="https://inkythuatso.com/uploads/thumbnails/800/2021/11/logo-iuh-inkythuatso-01-08-11-18-25.jpg?gidzl=GmVtIqAt_XP37lvBHw_z54yP-KvG_Ab3Lnce6L2kg1PPJVqR2_drIG98fnO1gQHF2HZmGcCnXp5hGBZq6G"
            alt="log-iuh"
        >
    </div>
    <div class="">
        <img
            class="logo-soKH-CN-LD"
            src="https://lamdong.gov.vn/sites/skhcn/hd-quanly/qlkh/SiteAssets/SitePages/logo%20so%20khcn-1653615024868.jpg"
            alt="log-soKH-CN-LD"
        >
    </div>
</div>
<div class="body">
    <div class="text-center">
        <img src="https://res.cloudinary.com/awi-ln/image/upload/v1656252034/Capture_vwungh.png" alt="logo">
        <p class="h2" style="margin: 0 auto">Thông số cảm biến của thiết bị</p>
    </div>
    <div class="parameter-item" *ngFor="let item of listValueSensor | async">
        <p><i class="icon-sensor {{item.icon}}"></i> {{item.title}} </p>
        <p class="text-value-sensor">{{item.value}} {{item.unit}}</p>
    </div>
</div>
