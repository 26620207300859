<div class="container-fluid">
    <span class="big-circle"></span>
    <img src="../../../assets/media/intro/shape.png" class="square" alt="" style="width: 100%"/>
    <div class="form">
        <div class="contact-info">
            <h3 class="title">Hãy giữ liên lạc</h3>
            <p class="text">
                Thư của bạn sẽ được nguổi ngay đến chúng tôi! Trog vòng một đến hai ngày chúng tôi sẽ liên hệ lại với bạn.
            </p>

            <div class="info">
                <div class="information">
                    <img src="../../../assets/media/intro/location.png" class="icon" alt="" />
                    <p>146 Phan Huy Ích TP.Hồ Chí Minh</p>
                </div>
                <div class="information">
                    <img src="../../../assets/media/intro/email.png" class="icon" alt="" />
                    <p>nhule@gmail.com</p>
                </div>
                <div class="information">
                    <img src="../../../assets/media/intro/phone.png" class="icon" alt="" />
                    <p>0985140102</p>
                </div>
            </div>

            <div class="social-media">
                <p>Liên lạc với chúng tôi :</p>
                <div class="social-icons">
                    <a href="#">
                        <i class="fab fa-facebook-f"></i>
                    </a>
                    <a href="#">
                        <i class="fab fa-twitter"></i>
                    </a>
                    <a href="#">
                        <i class="fab fa-instagram"></i>
                    </a>
                    <a href="#">
                        <i class="fab fa-linkedin-in"></i>
                    </a>
                </div>
            </div>
        </div>

        <div class="contact-form">
            <span class="circle one"></span>
            <span class="circle two"></span>

            <form action="index.html" autocomplete="off">
                <h3 class="title">Liên lạc với chúng tôi</h3>
                <div class="input-container">
                    <input type="text" name="firstname" class="input" placeholder="Họ và tên đệm"/>
                </div>
                <div class="input-container">
                    <input type="text" name="lastname" class="input" placeholder="Tên"/>
                </div>
                <div class="input-container">
                    <input type="email" name="email" class="input" placeholder="Email"/>
                </div>
                <div class="input-container">
                    <input type="tel" name="phone" class="input" placeholder="Phone"/>
                </div>
                <div class="input-container">
                    <input type="text" name="address" class="input" placeholder="Địa chỉ"/>
                </div>
                <div class="input-container textarea">
                    <textarea name="message" class="input" placeholder="Yêu cầu"></textarea>
                </div>
                <input type="submit" value="Gửi yêu cầu" class="btn" />
            </form>
        </div>
    </div>
</div>
