export const environment = {
  production: false,
  appVersion: 'v717demo1',
  USERDATA_KEY: 'authf649fc9a5f55',
  apiUrl: 'https://iot-lorawan.vdatlab.com',
  lorawanUrl: 'https://iot-lorawan.vdatlab.com/api',
  socketEndPoint: 'https://socketio.vdatlab.com',
  accountUrl: 'https://vdat-mcsvc-auth-gateway.vdatlab.com',
  lamDongURL: 'https://vdat-mcsvc-iot-lamdong.vdatlab.com',
  openIdConfig: {
    authUrl: `https://accounts.vdatlab.com/auth/realms/vdatlab.com/protocol/openid-connect/auth`,
    redirectUri: `http://localhost:4200/identity/`,
    logoutRedirectUri: `http://localhost:4200/`,
    identityAuth: `https://accounts.vdatlab.com/auth/realms/vdatlab.com/protocol/openid-connect/userinfo`,
    logoutUri: `https://accounts.vdatlab.com/auth/realms/vdatlab.com/protocol/openid-connect/logout`,
    audience: 'vvha',
    scope: 'openid profile email offline_access',
    responseType: 'code',
    clientId: 'ioh.apps.vdatlab.com',
    nonce: '',
    state: '',
    code_challenge_method: 'S256',
    url: 'https://accounts.vdatlab.com/auth',
    realm: 'vdatlab.com'
  }
};
