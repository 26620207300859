import { Injectable } from '@angular/core';
import {Socket} from 'ngx-socket-io';
import {map} from 'rxjs/operators';
import {Observable, pipe} from 'rxjs';
import {LorawanGarden} from '../../models/garden/lorawan-garden';

@Injectable({
  providedIn: 'root'
})
export class GardenSocketService {

  private currentGardenId: number | string;

  constructor(
    private socket: Socket
  ) {
    console.log('----------- Hello event');
    // this.socket.on('hello', res => {
    //   console.log('Hello response ---> ');
    //   console.log(res);
    // })
    const helloRes = this.socket.emit('hello', '123123', res => {
      console.log('-----------Interl ca')
      console.log(res);
      console.log('-----------------End')
    });
    console.log(helloRes);
    console.log(typeof helloRes);
  }


  sendMessage(msg: string) {
    this.socket.emit('join', msg);
  }

  leaveGarden(gardenId: number | string): void {
    this.socket.emit('leave', `garden${gardenId}`);
  }

  joinGarden(garden: LorawanGarden): void {
    if (this.currentGardenId) {
      this.leaveGarden(this.currentGardenId);
    }

    this.currentGardenId = garden.id;
    this.socket.emit('join',`garden${garden.id}`);
  }

  getMessage(): Observable<any> {
    return this.socket
      .fromEvent('uplink_message')
      .pipe(
        map((data) => data)
      );
  }
  getHistory(): Observable<any>{
    return this.socket
        .fromEvent('monitor_history')
        .pipe(
            map((data) => data)
    );
  }
}
