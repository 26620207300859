<div class="body">
    <div class="row content" style="background-image: url('https://res.cloudinary.com/awi-ln/image/upload/v1643037309/samples/landing_2_mbijuh.png');">
        <div class="col-5" >
            <p class="h1 text-white">
                Quản lý khu vườn một cách tiện lợi
            </p>
            <hr>
            <p class="h5 text-white">
                Bạn có thể theo dõi các chỉ số như nhiệt độ không khí, độ ẩm đất, cường độ ánh sáng...
                của khu vườn thông qua các thiết bị cảm biến....và
                bật tát máy bơm từ xa thông qua app hoặc website
            </p>
            <div class="float-left btn btn-success button" routerLink="/intro/contact">Liên Lạc</div>
            <div class="btn btn-secondary">Xem thêm</div>
        </div>
    </div>
    <div class="content col-12" style="margin-bottom: 300px">
        <div class="text-center">
            <p class="h1">Video Giới thiệu</p>
            <hr class="hr-center">
            <p class="h5">
                Video giới thiệu các chức năng ứng dụng và cách thức hoạt động của hệ thống
            </p>
            <video class="video" width="1000" height="600" autoplay controls>
                <source src="https://res.cloudinary.com/awi-ln/video/upload/v1643036103/samples/demo_online-video-cutter.com_1_hgfnki.mp4" type="video/mp4">
                Your browser does not support the video tag.</video>
        </div>
    </div>
    <div class="row content" style="">
        <div class="col-5">
            <img src="https://res.cloudinary.com/awi-ln/image/upload/v1643037266/samples/landing_3_yn6ng9.png">
        </div>
        <div class="col-2"></div>
        <div class="col-5">
            <p class="h1">
                Các giá trị của chỉ số cảm biến và hoạt động của máy bơm đều theo thời gian thực
            </p>
            <hr>
            <p class="h5">
                Bạn có thể theo dõi các chỉ số như nhiệt độ không khí, độ ẩm đất, cường độ ánh sáng...
                của khu vườn thông qua các thiết bị cảm biến....và
                bật tát máy bơm từ xa thông qua app hoặc website tương ứng với thời gian thực,
                Bật tắt máy bơm một cách nhanh chóng.
            </p>
            <div class="float-left btn btn-success button" routerLink="/intro/contact">Liên Lạc</div>
            <div class="btn btn-secondary">Xem thêm</div>
        </div>
    </div>
    <div class="row content" style="">
        <div class="col-5">
            <p class="h1">
                smartAgri App
            </p>
            <hr>
            <p class="h5">
                Ứng dụng smartAgri là cách tốt nhất để kết nối các thiết bị thông minh của bạn với Cloud
            </p>
            <div class="float-left btn btn-success button">Tải ngay</div>
            <div class="btn btn-secondary">Xem thêm</div>
        </div>
        <div class="col-2"></div>
        <div class="col-5">
            <img class="image" src="https://res.cloudinary.com/awi-ln/image/upload/v1643037317/samples/landing_1_btw84z.png">
        </div>
    </div>
    <div class="content col-12 service">
        <div class="text-center">
            <p class="h1">
                Chúng tôi đang cung cấp dịch vụ
            </p>
            <hr class="hr-center">
        </div>
        <div class="row">
            <div class="col-6 col-sm-6 col-md-6 card-distance" *ngFor="let item of listService">
                <div class="card-body card-custom card-shadowless gutter-b card-sensor">
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <div style="display: flex">
                                <div class="icon-big text-left text-center">
                                    <img class="icon-sensor" src="{{item.image}}" alt="image">
                                </div>
                                <div style="flex: 1">
                                    <div class="numbers">
                                        <p class="h2">{{item.title}}</p>
                                        <p class="">{{item.message}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
