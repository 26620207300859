<nav>
    <ul class="nav-list">
        <li class="nav-item">
            <a href="/" class="logo">
                <img class="image" src="../../../assets/media/logos/logo-letter-1.png" alt="logo"> smartAgri
            </a>
        </li>
        <li class="nav-item">
            <a class="btn btn-success" routerLink="/intro/contact">Liên Lạc</a>
        </li>
        <li class="nav-item">
            <a class="btn btn-secondary" routerLink="/auth/login">Đăng nhập</a>
        </li>
    </ul>
</nav>
