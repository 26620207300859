import { Injectable } from '@angular/core';
import {ApiService} from '../_core/api.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {ApiPath} from '../../constance/api-path';
import {catchError, map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';

const urlApi = environment.lorawanUrl;
const urlLamDong = environment.lamDongURL;
const eventPath = ApiPath.event;
@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(
      private apiService: ApiService
  ) { }

  getEventByDeviceId(deviceId): Observable<any> {
    const url = `${urlApi}/${eventPath.getEventByDeviceId(deviceId)}`;
    return this.apiService.get(url)
        .pipe(
            map((httpResponse: HttpResponse<any>) => {
              const body = httpResponse.body;
              return body;
            }),
            catchError(err => {
              console.log(err);
              return null;
            })
        );
  }
  getEventLoggerByLoggerID(loggerId): Observable<any>{
      const url = `${urlLamDong}/${eventPath.getEventLoggerByLoggerId(loggerId)}`;
      return this.apiService.get(url)
          .pipe(
              map((httpResponse: HttpResponse<any>) => {
                  const body = httpResponse.body;
                  return body;
              }),
              catchError(err => {
                  console.log(err);
                  return null;
              })
          )
  }

}
